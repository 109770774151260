import React from 'react';
import MainLayout from '../layouts/main';
import { Typography } from '@material-ui/core';
import styled from '../styled';
import PageHead from '../components/PageHead';
import { SEO } from '../components/SEO';
import { ContactForm } from '../components/ContactForm';

const LogosWrapper = styled('div')`
  display: grid;
`;

const LogoContainer = styled('div')`
  position: relative;
  height: 100%;
  border: 1px solid #f5f4f4;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.03);
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(4)}px;
`;

const Integrations = styled('div')`
  width: 100%;
  margin: 0 auto;
  color: #333;
  max-width: 600px;
  width: 80%;
  padding: 24px;
`;

const Headline = styled(Typography)`
  max-width: 800px;
  margin: 0 auto ${(p) => p.theme.spacing(3)}px;
  width: 90%;
  font-weight: 900;

  @media (max-width: 1200px) {
    font-size: ${(p) => p.theme.typography.h5.fontSize}px;
    line-height: ${(p) => p.theme.typography.h5.lineHeight};
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  }
`;

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/contact/"
          title="Contact Us"
          description="Contact Affilimate"
        />
        <PageHead style={{ marginBottom: 0 }}>
          <Headline variant="h4" component="h1">
            Contact Us
          </Headline>
          <Typography
            paragraph
            style={{ maxWidth: '500px', padding: '24px', margin: '0 auto' }}
          >
            Please get in touch with us using this form. We look forward to
            hearing from you!
          </Typography>
        </PageHead>
        <Integrations style={{ marginBottom: '24px' }}>
          <LogosWrapper>
            <LogoContainer>
              <ContactForm />
            </LogoContainer>
          </LogosWrapper>
        </Integrations>
      </div>
    </MainLayout>
  );
};
